import { gql } from '@apollo/client';

export const ADMIN_GET_LIST_BLOG = gql`
  query Blogs($input: FindBlogInput!, $countInput2: FindBlogInput!) {
    blogs(input: $input) {
      id
      isOnTop
      image
      no
      status
      tags
      title
      thumbnail
      content
      category {
        id
        title
      }
      updatedAt
      createdAt
      author {
        id
        name
      }
    }
    count(input: $countInput2)
  }
`;

export const USER_GET_LIST_BLOG = gql`
  query UserBlogs($input: FindBlogInput!, $countInput2: FindBlogInput!) {
    userBlogs(input: $input) {
      title
      categoryId
      category {
        id
        title
      }
      content
      createdAt
      id
      image
      isOnTop
      no
      status
      tags
      updatedAt
    }
    count(input: $countInput2)
  }
`;

export const GET_BLOG_CATEGORIES = gql`
  query BlogCategories {
    blogCategories {
      id
      title
    }
  }
`;

export const CREATE_BLOG = gql`
  mutation CreateBlog($input: CreateBlogInput!) {
    createBlog(input: $input) {
      id
      isOnTop
      image
      no
      status
      title
      updatedAt
      tags
      createdAt
      content
      categoryId
      category {
        id
        title
        updatedAt
        createdAt
      }
      author {
        id
        name
      }
    }
  }
`;

export const UPDATE_BLOG = gql`
  mutation Mutation($updateBlogInput: UpdateBlogInput!) {
    updateBlog(updateBlogInput: $updateBlogInput) {
      id
      image
      isOnTop
      no
      status
      title
      updatedAt
      tags
      createdAt
      content
      category {
        id
        title
      }
    }
  }
`;

export const DELETE_BLOG = gql`
  mutation RemoveBlog($removeBlogId: String!) {
    removeBlog(id: $removeBlogId) {
      id
    }
  }
`;

export const GET_BLOG_DETAIL = gql`
  query Query($blogDetailId: String!) {
    blogDetail(id: $blogDetailId) {
      id
      image
      isOnTop
      no
      status
      tags
      title
      updatedAt
      createdAt
      content
      categoryId
      category {
        id
        title
      }
      metaTitle
      metaDescription
      metaKeywords
    }
  }
`;

export const GET_DETAIL_BLOG_ADMIN = gql`
  query Blog($blogId: String!) {
    blog(id: $blogId) {
      id
      status
      no
      tags
      title
      updatedAt
      isOnTop
      image
      content
      category {
        id
        title
      }
      metaTitle
      metaDescription
      metaKeywords
    }
  }
`;

export const GET_OTHER_BLOGS = gql`
  query GetOtherBlogs($getOtherBlogsId: String!) {
    getOtherBlogs(id: $getOtherBlogsId) {
      id
      image
      isOnTop
      no
      status
      tags
      thumbnail
      title
      updatedAt
      createdAt
      content
      category {
        id
        title
      }
    }
  }
`;

export const GET_TAGS = gql`
  query Tags {
    tags {
      id
      title
    }
  }
`;

export const GET_HTML_META = gql`
  query Query($blogMetaHtmlId: String!) {
    blogMetaHtml(id: $blogMetaHtmlId) {
      id
      metaHtml
      title
    }
  }
`;
